import React, { useMemo, useState } from 'react'
import { ClockIcon } from '../../../assets/icons'
import moment from 'moment-timezone'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MuiThemeProvider from '../../../theme/mui'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { getNextHourAndRoundTo30Minutes } from '../../../services/helpers'
import { timesObj } from '../../../utils/times'
import { formattedTimezones, timezoneLabels } from '../../../utils/timezones'

const NewEventDateAndTime = ({ form }) => {
  const [dateOpen, setDateOpen] = useState(false)

  return (
    <MuiThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="bg-off-white flex flex-col justify-center mt-[12px] rounded-md border-1 cursor-pointer shadow-purple">
          <div className="flex relative w-full p-3">
            <div className="flex mt-[6px] mr-4">
              <ClockIcon />
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex gap-4 mb-2">
                <div className="flex-1 grow">
                  <Controller
                    name="date"
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      console.log('date value', value)
                      return (
                        <DatePicker
                          value={dayjs(value)}
                          format="dddd, MMM DD"
                          className="w-full"
                          open={dateOpen}
                          onOpen={() => setDateOpen(true)}
                          onClose={() => setDateOpen(false)}
                          closeOnSelect
                          disableClearable
                          minDate={dayjs(new Date())}
                          onChange={(v) => onChange(v)}
                          slotProps={{
                            actionBar: { hidden: true },
                            toolbar: { hidden: true },
                            openPickerButton: { hidden: true },
                            textField: {
                              onClick: () => setDateOpen(true),
                              variant: 'standard'
                            }
                          }}
                          error
                          helperText="Date is required"
                        />
                      )
                    }}
                  />
                </div>
                <div className="w-32">
                  <Controller
                    name="time"
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          id="time"
                          options={times}
                          autoHighlight
                          getOptionLabel={(v) => timesObj[v] ?? v}
                          value={value}
                          defaultValue={
                            value ??
                            moment(getNextHourAndRoundTo30Minutes()).format(
                              'HH:mm'
                            )
                          }
                          forcePopupIcon={false}
                          placeholder="Select time"
                          disableClearable
                          onChange={(_, v) => onChange(v)}
                          renderOption={({ key, ...optionProps }, option) => (
                            <Box key={key} component="li" {...optionProps}>
                              {timesObj[option] ?? option}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              defaultValue={value}
                              variant="standard"
                              sx={{
                                paddingRight: '0 !important',
                                backgroundColor: 'transparent !important'
                              }}
                            />
                          )}
                        />
                      )
                    }}
                  />
                </div>
              </div>
              <Controller
                name="timezone"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Autocomplete
                      id="timezone"
                      options={moment.tz.names()}
                      autoHighlight
                      value={value}
                      defaultValue={value ?? moment.tz.guess()}
                      forcePopupIcon={false}
                      placeholder="Select timezone"
                      disableClearable
                      onChange={(_, v) => onChange(v)}
                      getOptionLabel={(option) => {
                        const timezone = formattedTimezones.find((tz) => tz.value === option)

                        const removeZoneOffset = !!timezoneLabels[option]

                        return !!option
                          ? `${timezone?.label} ${removeZoneOffset ? '' : moment.tz(option).format('Z')}`
                          : ''
                      }}
                      renderOption={({ key, ...optionProps }, option) => {
                        const timezone = formattedTimezones.find((tz) => tz.value === option)

                        const removeZoneOffset = !!timezoneLabels[option]

                        return (
                          <Box key={key} component="li" {...optionProps}>
                            {timezone?.label} {removeZoneOffset ? "" : moment.tz(option).format('Z')}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue={value}
                          variant="standard"
                          sx={{
                            paddingRight: '0 !important',
                            backgroundColor: 'transparent !important'
                          }}
                        />
                      )}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}

export default NewEventDateAndTime

const times = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45'
]

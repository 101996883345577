import React, { useEffect, useState } from 'react'
import { LocationIcon } from '../../../assets/icons'
import { useWatch } from 'react-hook-form'
import { Tooltip } from '@chakra-ui/react'
import LocationDrawer from '../../EventDetails/LocationDrawer/LocationDrawer'
import queryString from 'query-string'

const NewEventLocation = ({
  form,
  address1,
  address2,
  setAddress1,
  setAddress2
}) => {
  const { eventLocation } = queryString.parse(window.location.search);
  const editEventLocation = !!eventLocation

  const [locationModal, setLocationModal] = useState(false)

  const [
    fieldComplementary,
    fieldCity,
    fieldState,
    fieldCountry,
    fieldWhere,
    fieldZip
  ] = useWatch({
    name: ['complementaryAddress', 'city', 'state', 'country', 'where', 'zip'],
    control: form.control
  })

  const handleConfirm = (formValues) => {
    form.setValue('zip', formValues?.zip)
    form.setValue('where', formValues?.where)
    form.setValue('city', formValues?.city)
    form.setValue('state', formValues?.state)
    form.setValue('country', formValues?.country)
    form.setValue('complementaryAddress', formValues?.complementaryAddress)

    setLocationModal(false)
  }

  const handleClear = () => {
    form.setValue('zip', undefined)
    form.setValue('where', undefined)
    form.setValue('city', undefined)
    form.setValue('state', undefined)
    form.setValue('country', undefined)
    form.setValue('complementaryAddress', undefined)

    setLocationModal(false)
  }

  useEffect(() => {
    if (editEventLocation) {
      setLocationModal(true)
    }
  }, [editEventLocation])

  return (
    <div
      className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md cursor-pointer border-1 shadow-purple"
      onClick={() => setLocationModal(true)}
    >
      <Tooltip
        label="Select another location"
        onClick={() => setLocationModal(true)}
      >
        <div className="flex flex-row items-center justify-start">
          <LocationIcon
            fill="#5b4abc"
            pinColor="#422d8f"
            className="mr-[8px]"
          />
          {address1 ? (
            <div className="flex flex-col">
              <span>
                {address1}
                {fieldComplementary ? `, ${fieldComplementary}` : ''}
              </span>
              <span>{address2}</span>
            </div>
          ) : (
            <span className="text-primary cursor-pointer font-semibold hover:underline">
              Add event location
            </span>
          )}
        </div>
      </Tooltip>

      <LocationDrawer
        isOpen={locationModal}
        handleOnClose={() => setLocationModal(false)}
        overrideConfirm={handleConfirm}
        handleClear={handleClear}
        setAddress1={setAddress1}
        setAddress2={setAddress2}
        editEventLocation={editEventLocation}
        avoidResetForm
        incommingAddress={{
          zip: fieldZip,
          where: fieldWhere,
          city: fieldCity,
          state: fieldState,
          country: fieldCountry,
          complementaryAddress: fieldComplementary,
          address1: address1 || undefined
        }}
      />
    </div>
  )
}

export default NewEventLocation

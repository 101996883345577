import React, { memo } from 'react'
import Base from '../../base'
import querystring from 'query-string'
import PreviewList from './PreviewList'
import PreviewSelectSong from './PreviewSelectSong'
import { useParams } from 'react-router-dom'
import PreviewHeader from './PreviewHeader'

const PreviewHelp = () => {
  const { circleCode } = useParams()

  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true} whiteLogo>
      <PreviewHeader />

      <div className="flex flex-1 flex-col md:items-stretch">
        {params.view === 'music' ? (
          <PreviewSelectSong circleCode={circleCode} />
        ) : params.view === 'list' ? (
          <PreviewList />
        ) : null}
      </div>
    </Base>
  )
}

export default memo(PreviewHelp)

import React from 'react'
import Button from '../../Button/Button'
import Modal from '../../Modal/Modal'

const EditOnCanvaModal = ({ isOpen, onClose, eventInfo }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="py-8 pr-6 bg-white rounded-input">
        <h2 className="pl-6">Edit the Event design in Canva</h2>

        <div className="flex pl-6 flex-col items-start justify-center mt-[10px]">
          You are about to be redirected to the Canva editor to edit your event
          design.
        </div>

        <div className={`flex flex-1 items-center justify-between mt-[20px]`}>
          <Button type="text" size="medium" text={'Cancel'} onClick={onClose} />
          <a
            href={`https://www.canva.com/design/${eventInfo.designId}/edit?ui=${process.env.REACT_APP_CANVA_APP_UI}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button text={'Continue'} size="medium" />
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default EditOnCanvaModal

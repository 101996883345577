import React from 'react'
import {
  Text,  
  Flex,  
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import SectionHeader from '../SectionHeader/SectionHeader'

const Faq = () => {
  const faqs = [
    {
      question: 'Is Coteri free?',
      answer:
        "Yes, Coteri is free to use. You can enjoy the core features and services provided by Coteri without any cost. Feel free to sign up and start using the platform right away! If you'd like to send emails through an email distribution, there is a small fixed cost associated with this. There will also be premium features added in the near future, so keep an eye out!"
    },
    {
      question: `How does Coteri work with my Spotify?`,
      answer: `Coteri guides you through the process of authorizing with your Spotify. Coteri is an approved application with these services and integrates through their standard OAuth procedures. Your guests are not asked to connect an account during the RSVP process. Coteri simply leverages these integrations to display song data to your guests for them to select from.`
    },
    {
      question: `Do I have to connect my Spotify to use Coteri to create the perfect party playlist?`,
      answer: `You do not need to connect an account when creating a playlist. This is an optional feature that allows you to view your music information from Spotify.`
    },
    {
      question: `Do you offer RSVP tracking?`,
      answer: `Yes! You can view RSVPs by clicking on the “View guests” button on your event page.`
    },
    {
      question: `What if I need to update the invitation after I’ve sent it?`,
      answer: `You can edit all details for your event by clicking on the “Edit event details” button on the event page. We recommend notifying your guests of any changes made after sending invites.`
    },
    {
      question: `What if I need help?`,
      answer: `Reach out to support@coteri.us if you require help at any time.`
    }
  ]

  return (
    <SectionHeader title={'FAQ'} subtitle={'Answers to your most asked questions'}>
        <Flex flex="1" w="100%" background={'#23212C'} p={3} rounded={6}>
          <Accordion w="100%" allowToggle>
            {faqs.map(({ question, answer }, index) => (
              <AccordionItem
                key={question}
                w="100%"                
                mb="10px"        
                border='none'
                borderBottom={index !== faqs.length - 1 ? '1px solid #EFEFEF50' : 'none'}        
              >
                <AccordionButton w="100%">
                  <Flex w="100%">
                    <Text flex="1" textAlign="start" fontWeight="700" color={'white'}>
                      {question}
                    </Text>
                  </Flex>
                  
                  <AccordionIcon color={'white'}/>
                </AccordionButton>

                <AccordionPanel pb={4} color={'white'}>{answer}</AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>   
    </SectionHeader>
  )
}

export default Faq

/* eslint-disable no-constant-condition */
import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  PlusIcon,
  RefreshIcon,
  SearchIcon,
  SpotifyIcon
} from '../../../assets/icons'
import MusicCard from '../../../components/EventDetails/MusicCard/MusicCard'
import Button from '../../../components/Button/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { maxLimitSongs } from '../../../services/helpers'
import { Container, Flex } from '@chakra-ui/react'
import SelectedModal from '../../../components/SelectedModal'
import TracksWErrorModal from '../../../components/TracksWErrorModal'
import SimilarSongsModal from '../../../components/SimilarSongsModal'
import { ref } from 'firebase/database'
import { database } from '../../../firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import Input from '../../../components/Input/Input'
import { useRef } from 'react'
import AddSongsViewCreatedEvent from '../../../components/EventDetailsView/AddSongsViewCreatedEvent/AddSongsViewCreatedEvent'
import { CheckIcon } from '@chakra-ui/icons'
import { previewSelectedSong, previewSongs } from './eventInfo'

const schema = yup
  .object({
    search: yup.string().notRequired(),
    filter: yup.string().notRequired()
  })
  .required()

const RSVPSelectSongsStep = ({
  circleCode,
  circleInfo,
  rsvpId,
  provider,
  option: initOption,
  hasResponse
}) => {
  const [popUp, setPopUp] = useState(null)
  const [selected, setSelected] = useState([previewSelectedSong])
  const [tracksWError, setTracksWError] = useState([])
  const [trackWithError, setTrackWithError] = useState(null)
  const [firstTimeModal, setFirstTimeModal] = useState(false)

  const navigate = useNavigate()

  const [memberSnap, memberLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${rsvpId || '1'}/userInfo`
    )
  )
  const [prevSelectedSongsSnap, prevSelectedSongsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/members/${rsvpId}/selected`)
  )
  const [latestTracksSnap, latestTracksLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/latestTracks`)
  )
  const [option, setOption] = useState()
  const memberInfo = memberSnap?.val()

  const prevSelectedSongs = useMemo(
    () => prevSelectedSongsSnap?.val() || [],
    [prevSelectedSongsSnap]
  )
  const maxLimit = maxLimitSongs(memberInfo?.order)
  const minLimit = 0

  const filterOptions = useMemo(
    () =>
      [
        {
          value: 'recommendations',
          label: 'Coteri suggestions'
        },
        {
          value: '4zAkxb3J6a5cM6KAP0nVGm',
          label: 'Popular Today'
        },
        {
          value: '7ejtgMFVvgow5Pk2Zq3KeG',
          label: 'Popular All Time'
        },
        {
          value: '6xMqitAbn0EF8pQs5UVUcZ',
          label: '2010s'
        },
        {
          value: '6oVSYR8QHmeN2tWSFqRm6o',
          label: '2000s'
        },
        {
          value: '16Zwb7N1OCH4JqzOAL9FLx',
          label: '1990s'
        },
        {
          value: '5pD9zzBjztHtTrABt7Abcb',
          label: '1980s'
        },
        {
          value: '3gHktisWbRE7EulXb0VCVh',
          label: '1970s'
        },
        {
          value: '0yN4owB2wQJ41oZFo9u8X3',
          label: '1960s'
        },
        option !== 'guest'
          ? {
              value: 'recently',
              label: 'Recently played songs'
            }
          : null,
        option !== 'guest'
          ? {
              value: 'library',
              label: 'Liked songs'
            }
          : null
      ].filter((x) => !!x),
    []
  )

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      search: '',
      filter: filterOptions[0]
    }
  })

  const [filter] = form.watch(['filter'])

  const searchSimilars = (item) => {
    setTrackWithError(item)
  }

  const toggleSelectSong = (item) => {
    const _selected = selected.concat([])
    const selectedIdx = _selected?.findIndex((x) => x.isrc === item.isrc)
    if (selectedIdx > -1) {
      _selected.splice(selectedIdx, 1)
    } else {
      _selected.push(item)
    }
    if (_selected.length === 0) {
      setPopUp(false)
    }
    setSelected(_selected)
  }

  const handleSelect = () => {
    navigate(`/event/${circleCode}/preview/details`)
  }

  const handlePreviousStep = () => {
    navigate(-1)
  }

  const songs = previewSongs

  const handleConfirm = () => {
    if (selected.length === 0) {
      handleSelect()
    } else {
      setPopUp('selected')
    }
  }

  useEffect(() => {
    setFirstTimeModal(true)
  }, [])

  return (
    <>
      <div className={`mt-[20px] flex flex-1 flex-col visible`}>
        <h2 className="text-[24px]">
          Add music to the playlist for this event.
        </h2>

        <span className="text-[14px] font-medium my-3">
          Click
          <span
            className={`w-[18px] h-[18px] inline-flex border-terciary border-1 items-center justify-center rounded-full mx-1`}
            style={{ verticalAlign: -7 }}
          >
            <PlusIcon fill={'#120a31'} />
          </span>
          to add songs to your cart, then click “Continue” when you’re ready to
          add them to the playlist.
        </span>

        <div className="flex w-full items-end justify-end">
          <div
            className="rounded-md bg-music-card px-4 py-2 cursor-pointer"
            onClick={() => {}}
          >
            <span className="text-[14px] font-medium mr-2 pointer-events-none">
              {selected?.length} {selected?.length === 1 ? 'song' : 'songs'}{' '}
              added
            </span>

            <CheckIcon color={'#422D8F'} />
          </div>
        </div>

        {false ? (
          <div className="flex-col items-center w-full rounded-lg bg-music-card text-heading p-4 mb-3 mt-2">
            <div className="flex gap-3 mb-2 font-semibold">
              <div className="w-[21px] h-[21px]">
                <SpotifyIcon fill="black" width={21} height={21} />
              </div>
              <div>Your Spotify account is connected.</div>
            </div>
            <div>
              View your Liked Songs and Recently Played songs using the filters
              below..
            </div>
          </div>
        ) : (
          <div className="flex items-center w-full rounded-lg bg-music-card text-heading p-4 mb-3 mt-2 sm:flex-row flex-col sm:gap-0 gap-4">
            <div className="flex-1">
              <div className="flex gap-3 mb-2 font-semibold sm:flex-row flex-col">
                <div className="text-primary">Optional</div>

                <div>Want to view information from your Spotify account?</div>
              </div>
              <div>View Liked Songs and Recently Played</div>
            </div>

            <button className="flex gap-2 border border-black rounded-full p-2">
              <div className="w-[21px] h-[21px]">
                <SpotifyIcon fill="black" width={21} height={21} />
              </div>
              <div className="text-sm">Connect your Spotify</div>
            </button>
          </div>
        )}

        <div className="flex items-center mt-[15px] sm:flex-row flex-col gap-4">
          <Input
            register={form.register('filter')}
            type={'select'}
            placeholder="Select filter"
            options={filterOptions}
            form={form}
            disabled={true}
          />
          <Input
            register={form.register('search')}
            form={form}
            type="icon"
            placeholder="Search for an artist or song..."
            className="w-full"
            iconRight={<SearchIcon fill="#000" />}
            disabled={true}
          />
        </div>

        {!filter?.value || filter?.value === 'recommendations' ? (
          <button className="flex items-center cursor-pointer mt-[20px]">
            <RefreshIcon fill={'#000'} width={24} height={24} />
            <span className="ml-[10px] text-secondary">
              Generate new list of song suggestions
            </span>
          </button>
        ) : null}

        <div className="flex flex-1 mb-28">
          <div className="flex-col w-full">
            <div className="mt-[15px] w-full">
              {songs.map((music, index) => {
                return (
                  <MusicCard
                    key={index}
                    music={music}
                    className="mb-3"
                    canPlay={true}
                    checked={[...selected, ...prevSelectedSongs].find(
                      (selectedMusic) => music.id === selectedMusic.id
                    )}
                    provider={provider}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button
              type="text"
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'Back'}
              onClick={handlePreviousStep}
            />
            <Button
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'View selections'}
              onClick={handleConfirm}
              iconRight={
                <span
                  className="ml-2 inline-flex items-center justify-center rounded-full bg-off-white"
                  style={{ width: '28px', height: '28px' }}
                >
                  <span className="text-secondary">
                    {selected?.length > 9 ? '9+' : selected?.length}
                  </span>
                </span>
              }
            />
          </div>
        </Container>
      </Flex>
      <SelectedModal
        uid={rsvpId}
        isGuest={true}
        hostID={circleInfo?.hostID}
        popUp={popUp}
        circleCode={circleCode}
        eventInfo={circleInfo}
        setPopUp={setPopUp}
        provider={provider}
        option={option}
        selected={selected}
        minLimit={minLimit}
        maxLimit={maxLimit}
        connections={[]}
        setTracksWError={setTracksWError}
        prevSelectedSongs={prevSelectedSongs}
        toggleSelectSong={toggleSelectSong}
        onSelect={handleSelect}
        preview
      />
      <TracksWErrorModal
        uid={rsvpId}
        selected={selected}
        tracksWError={tracksWError}
        setTracksWError={setTracksWError}
        setTrackWithError={setTrackWithError}
        provider={provider}
        option={option}
        circleCode={circleCode}
        minLimit={minLimit}
        maxLimit={maxLimit}
        connections={[]}
        searchSimilars={searchSimilars}
        onSelect={handleSelect}
      />
      <SimilarSongsModal
        track={trackWithError}
        circleCode={circleCode}
        circleInfo={circleInfo}
        provider={provider}
        option={option}
        maxLimit={maxLimit}
        selected={selected}
        prevSelectedSongs={prevSelectedSongs}
        connections={[]}
        setTrackWithError={setTrackWithError}
      />

      <AddSongsViewCreatedEvent
        isOpen={firstTimeModal}
        onClose={() => setFirstTimeModal(false)}
        rsvp={'rsvp'}
      />
    </>
  )
}

export default memo(RSVPSelectSongsStep)

import moment from 'moment-timezone'

export const timezoneLabels = {
  "America/Los_Angeles": "Pacific Standard Time (PST)",
  "America/New_York": "Eastern Standard Time (EST)",
  "America/Denver": "Mountain Standard Time (MST)",
  "America/Chicago": "Central Standard Time (CST)",
  "America/Puerto_Rico": "Atlantic Standard Time (AST)",
  "US/Alaska": "Alaska Standard Time (AKST)",
  "Pacific/Honolulu": "Hawai'i Standard Time (HST)"
};


export const formattedTimezones = moment.tz.names().map((tz) => {
  return {
    label: timezoneLabels[tz] || tz,
    value: tz
  }
})
import React, { memo } from 'react'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import ClipLoader from 'react-spinners/ClipLoader'
import Modal from '../../Modal/Modal'

const ChangeResponseModal = ({
  isOpen,
  handleOnClose,
  form,
  attendingOptions,
  handleUpdateRSVP,
  loading,
  preview
}) => {
  const handleConfirm = async () => {
    try {
      if (preview) {
        handleOnClose()

        return
      }

      await handleUpdateRSVP()

      handleOnClose()
    } catch (err) {
      console.log('ChangeResponseModal: ', err)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} className={'max-w-[400px] w-full'}>
      <div className="p-10 bg-white rounded-input mx-3 md:mx-0">
        <h2>Change your response:</h2>

        <Input
          register={form.register('attending')}
          type={'select'}
          className={'mt-[20px]'}
          placeholder="Select a response"
          options={attendingOptions}
          form={form}
        />

        {loading ? (
          <div className="mt-[15px] flex items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : (
          <div className="mt-[15px] flex items-center justify-between">
            <Button type="text" text={'Back'} onClick={handleOnClose} />
            <Button type="primary" text={'Confirm'} onClick={handleConfirm} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default memo(ChangeResponseModal)

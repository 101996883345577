import React, { useEffect, useState } from 'react'
import DottedLine from '../../../components/DottedLine/DottedLine'
import Toggle from '../../../components/Toggle/Toggle'
import Button from '../../../components/Button/Button'
import { useWatch } from 'react-hook-form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ref, update } from 'firebase/database'
import { database } from '../../../firebase'

export const schema = yup
  .object({
    costPerSquare: yup.string().optional(),
    venmo: yup.string().optional(),
    cashapp: yup.string().optional(),
    paypal: yup.string().optional(),
    zelle: yup.string().optional(),
    allowCash: yup.boolean().optional(),
    fixedPrice: yup.boolean().optional(),
    paymentNote: yup.string().optional()
  })
  .required()


const SquaresCostModal = ({ opened, onClose, circleCode, onContinue }) => {
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const [
    squareCost,
    venmo,
    cashapp,
    paypal,
    zelle,
    allowCash,
    fixedPrice,
    paymentNote
  ] = form.watch(['costPerSquare', 'venmo', 'cashapp', 'paypal', 'zelle', 'allowCash', 'fixedPrice', 'paymentNote'])

  const [error, setError] = useState(false)

  const hasCostError = fixedPrice && !squareCost

  const hasPaymentMethodError =
    squareCost > 0 && !venmo && !cashapp && !paypal && !zelle && !allowCash

  const onConfirm = async (formValues) => {
    try {
      if (!squareCost && !venmo && !cashapp && !paypal && !zelle && !allowCash) {
        setError(true)
        return
      }

      setError(false)

      const updatedData = {
        venmo: formValues.venmo || '',
        cashapp: formValues.cashapp || '',
        paypal: formValues.paypal || '',
        zelle: formValues.zelle || '',
        allowCash: formValues.allowCash || false,
        costPerSquare: formValues.costPerSquare || null,
        fixedPrice: formValues.fixedPrice || '',
        paymentNote: formValues.paymentNote || ''
      }

      await update(ref(database, `circles/${circleCode}/info`), updatedData)

      onContinue()
    } catch (error) {
      console.log('SquaresCostModal: error', error)
    }
  }

  return (
    <Modal isOpen={opened} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cost per guest</ModalHeader>

        <ModalCloseButton />

        <ModalBody className="!p-0">
          <div className="relative py-1">
            <div className="absolute right-0 top-0 left-6 bottom-0">
              <DottedLine color={'#80808033'} />
            </div>

            <div className="ml-6 mt-5 z-50 flex flex-1 flex-col mr-5">
              <div className="flex flex-row items-start mt-4">
                <div className="w-[3px] h-4 bg-primary" />
                <div className="flex flex-col ml-4">
                  <div className="flex-1 leading-4">
                    <span className="font-semibold pr-2">Payment methods</span>
                    <span className={`text-sm ${error ? 'text-red-600' : 'text-primary'}`}>
                      Required (min 1)
                    </span>
                  </div>
                  <div className="text-[12px]">
                    The way guests will pay you for event-related costs.
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-1 mt-3 ml-4">
                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Venmo
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={venmo}
                      onChange={(e) => form.setValue('venmo', e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Cash App
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">$</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={cashapp}
                      onChange={(e) => form.setValue('cashapp', e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Paypal
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={paypal}
                      onChange={(e) => form.setValue('paypal', e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Zelle
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary placeholder:text-[14px] placeholder:text-primary`}
                      placeholder="Email or phone number"
                      value={zelle}
                      onChange={(e) => form.setValue('zelle', e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex flex-row items-center">
                    <span className="text-primary  cursor-pointer mr-2">
                      Allow cash payment?
                    </span>
                    <Toggle
                      textOn="Yes"
                      textOff="No"
                      value={allowCash}
                      setValue={(v) => form.setValue('allowCash', v)}
                    />
                  </div>
                  <span className="text-[12px]">
                    Guest will have the option to pay in cash you upon arrival.
                  </span>
                </div>
              </div>

              <div className="flex flex-row items-start mt-4">
                <div className="w-[3px] h-4 bg-primary" />
                <div className="flex flex-col ml-4 gap-2">
                  <div className="font-semibold leading-4">Cost</div>

                  <div className="flex flex-row items-center">
                    <span className="cursor-pointer mr-2 text-[14px]">
                      Fixed cost per guest?
                    </span>
                    <Toggle
                      textOn="Yes"
                      textOff="No"
                      value={fixedPrice}
                      setValue={(v) => form.setValue('fixedPrice', v)}
                    />
                  </div>

                  <div className="flex flex-row items-center mb-2">
                    <span className="text-primary cursor-pointer w-[85px]">
                      Per guest
                    </span>
                    <div
                      className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white ${!fixedPrice && 'opacity-50'}`}
                    >
                      {fixedPrice && <span className=" text-primary ml-1 cursor-default">$</span>}

                      <input
                        type={!fixedPrice ? 'text' : 'number'}
                        className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                        min={1}
                        disabled={!fixedPrice}
                        placeholder="0"
                        value={!fixedPrice ? 'Variable' : squareCost}
                        onChange={(e) => form.setValue('costPerSquare', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`w-full h-[90px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white mt-5`}
              >
                <textarea
                  className={`w-full h-full mx-2 py-1 outline-none text-start min-w-0 text-primary resize-none bg-transparent placeholder:text-[13px]`}
                  placeholder='(Optional) Add a note for guests regarding their payment options'
                  value={paymentNote}
                  onChange={(e) => form.setValue('paymentNote', e.target.value)}
                />
              </div>
            </div>
          </div>

          {hasCostError || (fixedPrice && !squareCost) ? (
            <div className="flex px-6 text-red-500 mt-4">
              Must be greater than $0
            </div>
          ) : hasPaymentMethodError ? (
            <div className="flex px-6 text-red-500 mt-4">
              Please add at least one payment method
            </div>
          ) : null}
        </ModalBody>

        <ModalFooter className="!pl-0 !pr-4">
          <div className="flex w-full justify-between">
            <Button text={'Back'} type={'text'} onClick={onClose} disabled={form.formState.isSubmitting} />

            <Button
              text={'Save'}
              loading={form.formState.isSubmitting}
              onClick={form.handleSubmit(onConfirm)}
              disabled={hasCostError || (fixedPrice && !squareCost) || hasPaymentMethodError}
            />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SquaresCostModal

import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../../assets/icons'
import { twMerge } from 'tailwind-merge'

const Autocomplete = ({
  register,
  placeholder,
  className,
  hasError,
  form,
  onCompleteOptions,
  onSelect
}) => {
  const divRef = useRef(null)
  const spanRef = useRef(null)
  const [opened, setOpened] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const formValue = form?.getValues(register.name)
  const [options, setOptions] = useState(onCompleteOptions)

  const [text, setText] = useState(options.find((op) => op.value === formValue)?.label)

  const handlePlaceholderAnimation = useCallback(() => {
    if (text || inputFocused) {
      spanRef.current.style.top = '-2px'
      spanRef.current.style.fontSize = '12px'
      spanRef.current.style.background = '#fff'
      spanRef.current.style.padding = '0px 4px'
    } else {
      spanRef.current.style.top = '50%'
      spanRef.current.style.fontSize = '16px'
      spanRef.current.style.padding = '0px'
    }
  }, [text, inputFocused])

  const handleSelectOption = useCallback(
    (option) => {
      setText(option.label)
      setOpened(false)

      form?.setValue(register.name, option.value)

      if (onSelect) onSelect(option)
    },
    [form, register.name, onSelect]
  )

  const onFocus = () => {
    setInputFocused(true)
    setOpened(true)
  }

  const onBlur = () => {
    setTimeout(() => {
      if (!divRef.current?.contains(document.activeElement)) {
        setOpened(false)
        setInputFocused(false)
      }
    }, 200)
  }

  const handleOutsideClick = useCallback(
    (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpened(false)
        setInputFocused(false)
      }
    },
    []
  )

  useEffect(() => {
    handlePlaceholderAnimation()
  }, [text, handlePlaceholderAnimation, inputFocused, opened])

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        `${opened ? 'border-primary' : hasError ? 'border-error border-1' : 'border-input-border'}
        relative w-full px-2 min-w-24 h-h-input flex bg-primary border-1 rounded-input bg-transparent 
        ${opened && 'border-[2px]'} items-center`,
        className
      )}
    >
      <input
        type="text"
        className="w-full ml-1 mr-3 outline-none border-input-border bg-transparent"
        onKeyUp={(e) => {
          const filteredOptions = onCompleteOptions.filter((option) =>
            option.label.toLowerCase().includes(e.target.value.toLowerCase())
          )
          setOptions(filteredOptions)
        }}
        onChange={(event) => setText(event.target.value)}
        value={text}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
      />

      <span
        ref={spanRef}
        className={`${opened ? 'text-primary font-bold' : 'text-placeholder font-normal'}
        absolute top-1/2 -translate-y-1/2 pointer-events-none left-2.5 ease-linear duration-150`}
      >
        {placeholder}
      </span>

      <ArrowDownIcon opened={opened} />

      {opened && (
        <div className="border-1 absolute top-full flex flex-col items-start rounded-input right-0 left-0 mt-2 max-h-56 overflow-y-auto scrollbar-custom bg-background z-10 animate-fadeIn">
          {options.length === 0 ? (
            <div className="flex w-full p-2 rounded-input items-center justify-center scrollbar-custom pointer-events-none">
              <span className="text-placeholder pointer-events-none">Type to search</span>
            </div>
          ) : (
            options.map((option, index) => (
              <div
                key={index}
                className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer scrollbar-custom active:opacity-40"
                onClick={() => handleSelectOption(option)}
              >
                <span>{option.label}</span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default memo(Autocomplete)

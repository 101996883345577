import React, { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { database, ref } from '../firebase'

import { Container, Flex, useToast } from '@chakra-ui/react'
import RequestTabList from '../components/EventDetails/RequestTabList/RequestTabList'
import { useObject } from 'react-firebase-hooks/database'
import Button from '../components/Button/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import RequestListItemModal from '../components/EventDetails/RequestListItemModal/RequestListItemModal'

const SelectItems = ({ hasMusic, onFinish, guestsInfo, eventInfo }) => {
  const [itemModal, setItemModal] = useState(false)
  const { circleCode, rsvpId } = useParams()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  
  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )
  
  const itemsInfo = itemsSnap?.val()  

  const items = useMemo(
    () =>
      !!itemsInfo ? Object.keys(itemsInfo).map((key) => itemsInfo[key]) : [],
    [itemsInfo]
  )

  const handleNext = async () => {
    setLoading(true)
    try {
      if (!!hasMusic) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`)
        // await handleAddRSVPToMembers()
      } else {
        onFinish()
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log('handleClaimItems', e)
      toast({
        status: 'error',
        title: e.message || 'Error while saving items claimed',
        position: 'top'
      })
    }
  }

  const handleSkip = () => {
    navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
  }

  const handleItemModalClose = () => {
    setItemModal(false)
  }

  const handleItemModalOpen = () => {
    setItemModal(true)
  }

  return (
    <>
      <div className={`mt-[20px] visible flex flex-1 flex-col`}>
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <h2 className="text-[24px]">st</h2>
            <span>
              Bring something from the list below to the event. (Optional)
            </span>
          </div>
          <div className="flex  flex-col ml-[0px] items-center mt-[18px] md:mt-[0px] md:ml-[50px] ">
            <Button
              type="terciary"
              size="extra-small"
              text="Add+"
              onClick={handleItemModalOpen}
              className="md:w-full"
            />
          </div>
        </div>

        <div className="flex flex-1 mt-[15px] w-full flex-col mb-20">
          {itemsLoading ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <RequestTabList
              items={items}
              loading={false}
              rsvpId={rsvpId}
              eventInfo={eventInfo}
              guestsInfo={guestsInfo}
            />
          )}
        </div>
      </div>
      
      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button type="text" text={'Skip'} onClick={handleSkip} />
            <Button
              text={'Continue'}
              onClick={handleNext}
              disabled={isLoading}
            />
          </div>
        </Container>
      </Flex>

      <RequestListItemModal
        isOpen={itemModal}
        onClose={handleItemModalClose}
        userId={rsvpId}
      />
    </>
  )
}

export default SelectItems

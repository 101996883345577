import React, { useCallback, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import MuiThemeProvider from '../../../theme/mui'
import Button from '../../Button/Button'
import { EmailInvitesIcon } from '../../../assets/icons'
import DateTimeEditView from './DateTimeEditView'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'
import { ref, database } from '../../../firebase'
import { update } from 'firebase/database'
import { useToast } from '@chakra-ui/react'

const schema = yup
  .object({
    date: yup.string().required('Date is required'),
    time: yup.string().required('Time is required'),
    timezone: yup.string().required('Timezone is required')
  })
  .required()

const EmailDistributionDateTimeModal = ({
  isOpen,
  onClose,
  circleCode,
  eventInfo,
  onConfirm
}) => {
  const [isLoading, setLoading] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [hasError, setError] = useState(false)
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    const values = form.getValues()
    if (!!eventInfo && !values.date) {
      form.setValue('date', !!eventInfo.date ? moment(eventInfo.date) : null)
      form.setValue(
        'time',
        !!eventInfo.date && !!eventInfo.time ? eventInfo.time : null
      )
      form.setValue('timezone', eventInfo.timezone ?? moment.tz.guess())
    }
  }, [eventInfo, form])

  const onUpdateDateTime = useCallback(async () => {
    setLoading(true)

    const values = form.getValues()

    const formValues = {
      date: moment(new Date(values.date)).format('YYYY-MM-DD'),
      time: values.time,
      timezone: values.timezone
    }

    const isPastTime =
      moment().valueOf() >=
      moment
        .tz(
          `${formValues.date} ${formValues.time}`,
          'YYYY-MM-DD HH:mm',
          formValues.timezone
        )
        .add(2, 'hours')
        .valueOf()
    setError(isPastTime)

    console.log('isPastTime', formValues.date, isPastTime)

    if (isPastTime) {
      setLoading(false)
      return
    }

    // Update Date and Time if needed
    if (
      eventInfo.date !== formValues.date ||
      eventInfo.time !== formValues.time ||
      eventInfo.timezone !== formValues.timezone
    ) {
      try {
        await Promise.all([
          update(ref(database, `circles/${circleCode}/info`), {
            date: formValues.date,
            time: formValues.time,
            timezone: formValues.timezone
          })
        ])
        toast({
          status: 'success',
          title: 'Date updated',
          position: 'top'
        })
        onConfirm(true)
      } catch (e) {
        console.log('handleUpdateEvent', e)
        toast({
          status: 'error',
          title: e.message || 'Error while updating the date and time',
          position: 'top'
        })
        setLoading(false)
      }
    } else {
      onConfirm(false)
    }
  }, [form, circleCode, eventInfo, onConfirm, toast])

  return (
    <MuiThemeProvider>
      <Modal
        open={isOpen}
        onClose={onClose}
        className="bg-background-1/60 fixed inset-0 z-20 flex min-h-screen items-center justify-center overflow-hidden text-center outline-none"
      >
        <div className="relative inline-flex max-h-[calc(100vh-4rem)] flex-col overflow-hidden rounded-xl bg-white text-left align-center shadow-xl sm:my-8 sm:w-full sm:max-w-md p-4">
          <div className="flex flex-col items-center">
            <EmailInvitesIcon width={120} height={80} />
            <p className="text-[20px] mt-3">Confirm Date and Time</p>
            <p className="text-center text-[14px] mt-2">
              Confirm that the date and time of the event looks correct.
            </p>
            {/* <p className="text-center text-[14px] mt-4 p-4 bg-yellow-200 text-yellow-700 rounded-md">
              Note: After upgrading the event, date and time cannot be changed.
            </p> */}
          </div>
          <DateTimeEditView
            eventInfo={eventInfo}
            form={form}
            isEditing={isEditing}
            setEditing={setEditing}
          />
          {hasError ? (
            <div className="text-error mt-2">
              Event time cannot be in the past
            </div>
          ) : null}
          <div className="flex sm:flex-row flex-col items-center justify-end my-5 sm:gap-0 gap-5 z-50">
            <Button
              text={isEditing ? 'Update' : 'Yes, looks good!'}
              onClick={onUpdateDateTime}
              loading={isLoading}
              size={'small'}
            />
          </div>
        </div>
      </Modal>
    </MuiThemeProvider>
  )
}

export default EmailDistributionDateTimeModal

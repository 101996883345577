import React from 'react'
import Base from '../../../pages/base'
import Footer from '../../Footer/Footer'

const EventClosed = () => {
  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}       
      mainDivClassName={'bg-[#02000B] sm:h-[calc(100vh-80px)] h-auto'}      
    >
      <div 
        className="flex flex-1 flex-col mt-[60px] items-center w-full justify-between h-full"
      >
        <div className='sm:mt-44 mt-16 sm:mb-0 mb-28'>
          <p className='text-white text-center sm:text-[30px] text-[24px]'>This event is closed and no longer accepting RSVPs.</p>

          <p className='text-white text-center mt-10 sm:text-[18px] text-[12px]'>Please contact the host to request the event be reopened.</p>
        </div>

        <Footer className={'w-full'}/>     
      </div>
    </Base>
  )
}

export default EventClosed

import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../../Modal/Modal'
import Button from '../../Button/Button'
import Toggle from '../../Toggle/Toggle'
import CopyLink from '../../CopyLink'
import { ref, update } from 'firebase/database'
import { database } from '../../../firebase'
import { useNavigate } from 'react-router-dom'
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { Close } from '@mui/icons-material'
import * as amplitude from '@amplitude/analytics-browser'

const EventDetailsViewInviteDrawer = ({
  isOpen,
  onClose,
  eventInfo,
  circleCode
}) => {
  const navigate = useNavigate()
  const [toggled, setToggled] = useState(!!eventInfo?.allowGuests)

  const handleEnableInviteLinkModalConfirm = useCallback(
    async (allowGuests) => {
      try {
        await update(ref(database, `circles/${circleCode}/info`), {
          allowGuests
        })
      } catch (err) {
        console.error('EventDetailsView: ', err)
      }
    },
    [circleCode]
  )

  const handleDistribution = () => {
    amplitude.track('Create Email Distribution Button Clicked', {
      event_id: circleCode
    })
    navigate(`/event/${circleCode}/email-distribution`)
  }

  const handleToggle = useCallback(async () => {
    const newToggled = !toggled
    setToggled(newToggled)
    await handleEnableInviteLinkModalConfirm(newToggled)
  }, [toggled, handleEnableInviteLinkModalConfirm])

  useEffect(() => {
    setToggled(!!eventInfo?.allowGuests)
  }, [eventInfo?.allowGuests])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : 'sm'}>
      <DrawerOverlay />

      <DrawerContent>
        <Close
          className="mt-3 self-end mr-4 cursor-pointer"
          fontSize="large"
          onClick={onClose}
        />

        <DrawerHeader />

        <div className="bg-white rounded-input relative w-full flex flex-col px-4">
          <h2 className="pr-4 !font-normal text-lg">
            Start inviting guests using one of the methods below
          </h2>

          <div className="w-full h-[1px] bg-[#80808033] mt-2" />

          <div className="py-2 text-secondary mb-5">
            <h2 className="!font-semibold">
              Send invites using the link below
            </h2>

            <div className="flex items-center justify-between mt-3 mb-2">
              <span className="mr-2 text-[14px] flex-1 text-wrap">
                To {toggled ? 'stop' : 'continue'} accepting invites, turn{' '}
                {toggled ? 'off' : 'on'} access here.
              </span>

              <Toggle
                textOn="On"
                textOff="Off"
                value={toggled}
                setValue={handleToggle}
              />
            </div>

            <div className="flex flex-row items-start mt-4">
              <span className="text-[14px] text-black">
                Copy the invite link, then paste it into an email or text
                message to send to your guests.
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center mb-3 z-50">
            <CopyLink
              id="copy-select"
              isDisabled={!eventInfo?.allowGuests}
              circleCode={circleCode}
              url={
                eventInfo.type === 'event'
                  ? `event/${circleCode}/rsvp/new`
                  : `join/${circleCode}`
              }
            />
          </div>

          <div className="flex flex-row items-center">
            <div className="w-full h-[1px] bg-[#80808033]" />

            <span className="mx-5 text-slate-400 text-md">and/or</span>

            <div className="w-full h-[1px] bg-[#80808033]" />
          </div>

          <div className="py-2 text-secondary mb-5 mt-2">
            <h2 className="!font-semibold">
              Send invites using an email distribution
            </h2>

            <div className="flex flex-row items-start mt-4">
              <span className="text-[14px] text-black">
                Send invites by adding emails to a distribution list and sending
                through our email tool.
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center mb-8 z-50">
            <Button
              type={'border'}
              text={'Create email distribution'}
              size={'small'}
              className="text-heading border-[#120a31]"
              onClick={handleDistribution}
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default EventDetailsViewInviteDrawer

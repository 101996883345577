import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, signOut } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import LoginBox from '../components/LoginBox'
import Base from './base'
import { Flex, Spinner } from '@chakra-ui/react'
import querystring from 'query-string'
import * as amplitude from '@amplitude/analytics-browser'

const Login = () => {
  const [user, loading] = useAuthState(auth)
  const [shouldSignOut, setShouldSignOut] = useState(true)
  const navigate = useNavigate()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  useEffect(() => {
    if (!loading) {
      if (user) {
        if (!!params.canva_user_token && !!params.state) {
          if (!!shouldSignOut) {
            setShouldSignOut(false)
            amplitude.track('Logged Out', {
              email: user.email
            })
            // removeSettingsData()
            signOut(auth)
          }
        } else if (!!params.redirect_url) {
          navigate(params.redirect_url, { replace: true })
        } else {
          navigate('/', { replace: true })
        }
      } else {
        setShouldSignOut(false)
      }
    }
  }, [user, loading])

  if (loading || !!user)
    return (
      <Base hideMenu={true}>
        {((!!params.canva_user_token && !!params.state) ||
          !!params.redirect_url) && (
          <Flex flex="1" align="center" justify="center">
            <Spinner size="lg" color="primary" />
          </Flex>
        )}
      </Base>
    )

  return (
    <Base hideMenu={true}>
      <Flex justify="center">
        <Flex w="450px">
          <LoginBox />
        </Flex>
      </Flex>
    </Base>
  )
}

export default Login

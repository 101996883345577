import moment from "moment"
import { formattedTimezones } from "./timezones"

export const timeOfEvent = (eventInfo) => {
  if (!eventInfo || !eventInfo.date || !eventInfo.time || !eventInfo.timezone)
    return 'No time set'

  const timezones = formattedTimezones.find((tz) => tz.value === eventInfo.timezone)

  return moment(`${eventInfo.date}T${eventInfo.time}`).format(
    `hh:mm A [${timezones?.label || eventInfo.timezone}]`
  )
}

import React from 'react'
import Button from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'

const PreviewHeader = () => {
  const navigate = useNavigate()

  const exitPreview = () => {
    const circleCode = localStorage.getItem('preview')

    if (circleCode) {
      navigate(`/event/${JSON.parse(circleCode)}`)
      localStorage.removeItem('preview')
    }
  }

  return (
    <>
      <div className='absolute w-full left-0 top-20 md:h-16 sm:h-28 h-40 bg-primary flex items-center justify-between sm:px-10 px-5 sm:flex-row flex-col sm:py-2 py-5 gap-3'>
        <Button type={'border'} text={'Exit preview'} className={'text-white border-white sm:order-0 order-1'} size={'small'} onClick={exitPreview} />

        <div className='text-center items-center flex flex-col flex-1 sm:order-1 order-0'>
          <span className='text-white'>EXAMPLE</span>
          <span className='text-white text-sm'>This is an example of how an invitation appears to guests. Items in this example are not clickable.</span>
        </div>
      </div>
      <div className='md:h-16 sm:h-28 h-40' />
    </>
  )
}

export default PreviewHeader

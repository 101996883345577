import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Flex } from '@chakra-ui/react'
import Button from '../../../components/Button/Button'
import RequestTabList from '../../../components/EventDetails/RequestTabList/RequestTabList'
import { itemsInfo } from './eventInfo'

const PreviewList = ({ eventInfo }) => {
  const { circleCode, rsvpId } = useParams()
  const navigate = useNavigate()

  const handleNext = async () => {
    navigate(`/event/${circleCode}/preview/help?view=music`)
  }

  const handleSkip = () => {
    navigate(`/event/${circleCode}/preview/details`)
  }

  return (
    <>
      <div className={`mt-[20px] visible flex flex-1 flex-col`}>
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <h2 className="text-[24px]">Help out your host</h2>
            <span>
              Bring something from the list below to the event. (Optional)
            </span>
          </div>
          <div className="flex  flex-col ml-[0px] items-center mt-[18px] md:mt-[0px] md:ml-[50px] ">
            <Button
              type="terciary"
              size="extra-small"
              text="Add+"
              onClick={() => { }}
              className="md:w-full"
            />
          </div>
        </div>

        <div className="flex flex-1 mt-[15px] w-full flex-col mb-20">
          <RequestTabList
            items={itemsInfo}
            loading={false}
            rsvpId={rsvpId}
            eventInfo={eventInfo}
            guestsInfo={[]}
          />
        </div>
      </div>

      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button type="text" text={'Skip'} onClick={handleSkip} />
            <Button
              text={'Continue'}
              onClick={handleNext}
            />
          </div>
        </Container>
      </Flex>
    </>
  )
}

export default PreviewList

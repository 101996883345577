import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as CryptoJS from 'crypto-js'
import {
  auth,
  updateAuthProfile,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from '../firebase'
import { Button, Flex, Input, Spinner, Text, useToast } from '@chakra-ui/react'
import { updateUser } from '../services/User'
import { ReactComponent as GoogleSVG } from '../assets/icons/google.svg'
import querystring from 'query-string'
import { setCanvaUser } from '../services/helpers'
import * as amplitude from '@amplitude/analytics-browser'

const RegisterBox = ({ onSuccess, onChangePopUp }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  const handleSignInWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider()
      const res = await signInWithPopup(auth, googleProvider)
      const hashedEmail = CryptoJS.SHA256(
        auth.currentUser.email.trim().toLowerCase()
      ).toString(CryptoJS.enc.Hex)

      amplitude.track('Signed Up', {
        auth_provider: 'Google',
        user_type:
          !!params.canva_user_token && !!params.state ? 'Canva' : 'Organic',
        signup_at: new Date().toISOString(),
        name,
        email: auth.currentUser.email,
        hashedEmail
      })

      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('email', auth.currentUser.email)
      identifyEvent.set('hashedEmail', hashedEmail)

      amplitude.identify(identifyEvent)

      await updateUser({
        name: res.user?.displayName,
        email: auth.currentUser.email
      })
      if (!!params.canva_user_token && !!params.state) {
        await setCanvaUser(
          res.user.uid,
          params.canva_user_token,
          params.state,
          params.nonce,
          toast
        )
        return
      }
      if (!!onSuccess) {
        onSuccess()
      } else if (!!params.redirect_url) {
        navigate(params.redirect_url)
      }
    } catch (err) {
      console.error(err)
      toast({
        zIndex: 999999,
        title: err.message || 'Error while signing with google',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  const handleRegister = async () => {
    if (!name || !email || !password) {
      return toast({
        zIndex: 999999,
        title: 'Required fields are empty',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
    if (password !== confirmPass) {
      return toast({
        zIndex: 999999,
        title: 'Passwords do not match',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
    setLoading(true)
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password)
      await updateAuthProfile(user.user, { displayName: name })
      const hashedEmail = CryptoJS.SHA256(
        auth.currentUser.email.trim().toLowerCase()
      ).toString(CryptoJS.enc.Hex)

      amplitude.track('Signed Up', {
        auth_provider: 'Email',
        user_type:
          !!params.canva_user_token && !!params.state ? 'Canva' : 'Organic',
        signup_at: new Date().toISOString(),
        name,
        email: auth.currentUser.email,
        hashedEmail
      })

      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('email', auth.currentUser.email)
      identifyEvent.set('hashedEmail', hashedEmail)
      amplitude.identify(identifyEvent)

      await updateUser({
        name,
        email: auth.currentUser.email
      })
      if (!!params.canva_user_token && !!params.state) {
        await setCanvaUser(
          user.user.uid,
          params.canva_user_token,
          params.state,
          params.nonce,
          toast
        )
        return
      }
      if (!!onSuccess) {
        onSuccess()
        setLoading(false)
      } else if (!!params.redirect_url) {
        navigate(params.redirect_url)
      }
    } catch (e) {
      setLoading(false)
      toast({
        zIndex: 999999,
        title: e.message || 'Error while creating account',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  return (
    <Flex w="100%" direction="column" px="10px">
      <Text mb="28px" color="black" fontSize="20px" fontWeight="600">
        Sign Up
      </Text>
      <Button
        h="50px"
        mb="20px"
        bg="white"
        color="black"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        onClick={handleSignInWithGoogle}
        leftIcon={<GoogleSVG width={22} style={{ marginRight: 4 }} />}
      >
        Continue with Google
      </Button>
      <Text textAlign="center" color="gray.700" mb="20px">
        or
      </Text>
      <Input
        type="text"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        required={true}
        _hover={{ borderColor: 'gray.600' }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
      />
      <Input
        type="text"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        required={true}
        _hover={{ borderColor: 'gray.600' }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Input
        type="password"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        required={true}
        _hover={{ borderColor: 'gray.600' }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Input
        type="password"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        required={true}
        _hover={{ borderColor: 'gray.600' }}
        value={confirmPass}
        onChange={(e) => setConfirmPass(e.target.value)}
        placeholder="Confirm Password"
      />
      <Button
        h="50px"
        mb="20px"
        bg="black"
        color="white"
        colorScheme="pink"
        borderRadius="10px"
        borderColor="black"
        onClick={handleRegister}
        isDisabled={loading}
        isLoading={loading}
      >
        {loading ? <Spinner /> : 'Create Account'}
      </Button>
      <Flex justify="center" mb="10px">
        <Text color="gray.600">Already have an account?</Text>
        {onChangePopUp ? (
          <Text
            color="primary"
            cursor="pointer"
            ml="4px"
            onClick={() => onChangePopUp('login')}
          >
            Sign In
          </Text>
        ) : (
          <Link
            to={`/login?${new URLSearchParams(params).toString()}`}
            color="primary"
          >
            <Text color="primary" ml="4px">
              Sign In
            </Text>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default RegisterBox

import React, { useEffect, useRef, useState } from 'react'
import DottedLine from '../../DottedLine/DottedLine'
import Toggle from '../../Toggle/Toggle'
import Button from '../../Button/Button'
import { useWatch } from 'react-hook-form'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { Close } from '@mui/icons-material'

const NewEventCostDrawer = ({ hasOldCost, isOpen, onClose, form }) => {
  const [squareCost, setSquareCost] = useState(undefined)
  const [venmo, setVenmo] = useState(undefined)
  const [cashapp, setCashapp] = useState(undefined)
  const [paypal, setPaypal] = useState(undefined)
  const [zelle, setZelle] = useState(undefined)
  const [allowCash, setAllowCash] = useState(false)
  const [fixedCostPerGuest, setFixedCostPerGuest] = useState(false)
  const [note, setNote] = useState('')

  const [error, setError] = useState(false)

  const hasCostError = fixedCostPerGuest && !squareCost

  const hasPaymentMethodError =
    squareCost > 0 && !venmo && !cashapp && !paypal && !zelle && !allowCash

  const [
    squareCostValue,
    venmoValue,
    cashappValue,
    paypalValue,
    zelleValue,
    allowCashValue,
    fixedPrice,
    paymentNote
  ] = useWatch({
    name: ['costPerSquare', 'venmo', 'cashapp', 'paypal', 'zelle', 'allowCash', 'fixedPrice', 'paymentNote'],
    control: form.control
  })

  const onConfirm = () => {
    if (!squareCost && !venmo && !cashapp && !paypal && !zelle && !allowCash) {
      setError(true)
      return
    }

    setError(false)

    form.setValue('costPerSquare', squareCost)
    form.setValue('venmo', venmo)
    form.setValue('cashapp', cashapp)
    form.setValue('paypal', paypal)
    form.setValue('zelle', zelle)
    form.setValue('allowCash', allowCash)
    form.setValue('fixedPrice', fixedCostPerGuest)
    form.setValue('paymentNote', note)

    onClose()
  }

  const onDelete = () => {
    form.setValue('costPerSquare', undefined)
    form.setValue('venmo', undefined)
    form.setValue('cashapp', undefined)
    form.setValue('paypal', undefined)
    form.setValue('zelle', undefined)
    form.setValue('allowCash', undefined)
    form.setValue('fixedPrice', undefined)
    form.setValue('paymentNote', undefined)
    onClose()
  }

  useEffect(() => {
    setSquareCost(squareCostValue)
    setVenmo(venmoValue)
    setCashapp(cashappValue)
    setPaypal(paypalValue)
    setZelle(zelleValue)
    setAllowCash(allowCashValue)
    setFixedCostPerGuest(fixedPrice)
    setNote(paymentNote)
  }, [allowCashValue, cashappValue, fixedPrice, paymentNote, paypalValue, squareCostValue, venmoValue, zelleValue])

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size={isMobile ? 'full' : 'sm'}
    >
      <DrawerOverlay />

      <DrawerContent>
        <Close className='mt-3 self-end mr-4 cursor-pointer' fontSize='large' onClick={onClose} />

        <DrawerHeader>Cost per guest</DrawerHeader>

        <DrawerBody className="!p-0">
          {hasOldCost ? (
            <div className="flex justify-end px-6">
              <button
                className="text-red-500 underline text-[14px] cursor-pointer"
                onClick={onDelete}
              >
                delete
              </button>
            </div>
          ) : null}

          <div className="relative py-1 z-50 flex flex-1 flex-col mr-5 px-[26px]">
            <div className="flex flex-col items-start">
              <div className="flex-1 leading-4">
                <span className="font-semibold pr-2">Payment methods</span>
                <span className={`text-sm ${error ? 'text-red-600' : 'text-primary'}`}>
                  Required (min 1)
                </span>
              </div>

              <div className="text-[12px]">
                The way guests will pay you for event-related costs.
              </div>
            </div>

            <div className="flex flex-col gap-1 mt-3">
              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Venmo
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">@</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={venmo}
                    onChange={(e) => setVenmo(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Cash App
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">$</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={cashapp}
                    onChange={(e) => setCashapp(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Paypal
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">@</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={paypal}
                    onChange={(e) => setPaypal(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Zelle
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary placeholder:text-[14px] placeholder:text-primary`}
                    placeholder="Email or phone number"
                    value={zelle}
                    onChange={(e) => setZelle(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex flex-row items-center">
                  <span className="text-primary  cursor-pointer mr-2">
                    Allow cash payment?
                  </span>
                  <Toggle
                    textOn="Yes"
                    textOff="No"
                    value={allowCash}
                    setValue={setAllowCash}
                  />
                </div>
                <span className="text-[12px]">
                  Guest will have the option to pay in cash you upon arrival.
                </span>
              </div>
            </div>

            <div className="flex flex-row items-start mt-4">
              <div className="flex flex-col gap-4">
                <div className='flex flex-row items-center gap-3'>
                  <div className="font-semibold leading-4">Cost</div>

                  <Toggle
                    textOn="On"
                    textOff="Off"
                    value={fixedCostPerGuest}
                    setValue={setFixedCostPerGuest}
                  />
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary cursor-pointer w-[85px]">
                    Per guest
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white ${!fixedCostPerGuest && 'opacity-50'}`}
                  >
                    {fixedCostPerGuest && <span className=" text-primary ml-1 cursor-default">$</span>}

                    <input
                      type={'text'}
                      inputMode='decimal'
                      pattern="[0-9,.]*"
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      min={1}
                      disabled={!fixedCostPerGuest}
                      placeholder="0"
                      value={!fixedCostPerGuest ? '' : squareCost}
                      onChange={(e) => {
                        let value = e.target.value;

                        // Allow only numbers, comma (,) for thousands, and dot (.) for decimals
                        if (!/^[\d,.]*$/.test(value)) return;

                        // Ensure there's only one dot (for decimals)
                        if ((value.match(/\./g) || []).length > 1) return;

                        // Prevent multiple commas in a row (e.g., "1,,293")
                        if (value.includes(",,") || value.startsWith(",")) return;

                        // If there's a dot, ensure it's used for decimal places
                        let parts = value.split(".");
                        if (parts.length > 2) return; // Prevent multiple dots

                        // Prevent more than 2 decimal places
                        if (parts.length === 2 && parts[1].length > 2) return;

                        setSquareCost(value);
                      }}
                      onBlur={(e) => {
                        let value = e.target.value;

                        if (!value) return

                        // Remove invalid commas (e.g., leading or trailing ones)
                        value = value.replace(/^,|,$/g, "");

                        // Ensure correct thousands separator formatting
                        let [integerPart, decimalPart] = value.split(".");
                        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                        // Format decimals correctly
                        if (!decimalPart) {
                          value = `${integerPart}.00`; // If no decimal, add ".00"
                        } else if (decimalPart.length === 1) {
                          value = `${integerPart}.${decimalPart}0`; // If one decimal, add trailing zero
                        } else {
                          value = `${integerPart}.${decimalPart}`; // Keep two decimals
                        }

                        setSquareCost(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`w-full h-[90px] rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white mt-5`}
            >
              <textarea
                className={`w-full h-full mx-2 py-1 outline-none text-start min-w-0 text-primary resize-none bg-transparent placeholder:text-[13px]`}
                placeholder='(Optional) Add a note for guests regarding their payment options'
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>

          {hasCostError || (fixedCostPerGuest && !squareCost) ? (
            <div className="flex px-6 text-red-500 mt-4">
              Must be greater than $0
            </div>
          ) : hasPaymentMethodError ? (
            <div className="flex px-6 text-red-500 mt-4">
              Please add at least one payment method
            </div>
          ) : null}
        </DrawerBody>

        <DrawerFooter mb={{ base: "5%", sm: "25%", md: "25%", lg: 0, xl: 0 }}>
          <div className="flex w-full justify-end">
            <Button
              text={'Save'}
              onClick={onConfirm}
              disabled={hasCostError || (fixedCostPerGuest && !squareCost) || hasPaymentMethodError}
            />
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default NewEventCostDrawer

import React, { memo, useMemo, useState } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  PeopleOutlineIcon
} from '../assets/icons'
import moment from 'moment'
import { CostIcon } from '../assets/icons'
import { useNavigate } from 'react-router-dom'
import HostNameModal from './EventDetails/HostNameModal/HostNameModal'
import EditEvent from './EventDetails/EditEvent/EditEvent'
import { ref, update } from 'firebase/database'
import { database } from '../firebase'
import DisableInviteLinkModal from './EventDetails/DisableInviteLinkModal/DisableInviteLinkModal'
import EnableInviteLinkModal from './EventDetails/EnableInviteLinkModal/EnableInviteLinkModal'
import EventDetailsViewInviteDrawer from './EventDetailsView/EventDetailsViewInviteDrawer/EventDetailsViewInviteDrawer'
import { eventTimePassed } from '../helpers'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { More, MoreVert } from '@mui/icons-material'
import { formattedTimezones } from '../utils/timezones'
import { timeOfEvent } from '../utils/event'
import * as amplitude from '@amplitude/analytics-browser'

const EventDetailsView = ({
  circleCode,
  eventInfo,
  guestsInfo,
  hideUndefineFields
}) => {
  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  const [editEventModal, setEditEventModal] = useState(false)
  const [hostNameModal, setHostNameModal] = useState(false)
  const [enableInviteLinkModal, setEnableInviteLinkModal] = useState(false)
  const [disableInviteLinkModal, setDisableInviteLinkModal] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const navigate = useNavigate()

  const hasPaymentOption =
    eventInfo?.allowCash ||
    eventInfo?.cashapp ||
    eventInfo?.paypal ||
    eventInfo?.venmo ||
    eventInfo?.zelle ||
    eventInfo?.fixedPrice ||
    eventInfo?.costPerSquare
  const noFixedPrice = !eventInfo?.fixedPrice

  const handleEnableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: true
      })

      setEnableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleEnableInviteLinkModalClose = () => {
    setEnableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalClose = () => {
    setDisableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: false
      })

      setDisableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleDeleteNote = () => {
    try {
      update(ref(database, `circles/${circleCode}/info`), {
        noteForGuests: ''
      })
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  if (!eventInfo) {
    return null
  }

  return (
    <div className="flex flex-col flex-1">
      <div>
        <div className="flex flex-row items-center justify-between mb-[15px]">
          <span className="mr-[35px] font-medium text-[24px]">
            {eventInfo.name}
          </span>
          {/* <Menu>
            <MenuButton>
              <MoreVert />              
            </MenuButton>
            <MenuList>
              <MenuItem>Edit</MenuItem>
              <MenuItem>Preview event</MenuItem>
            </MenuList>
          </Menu> */}
          <button
            className="text-primary cursor-pointer font-bold hover:underline min-w-[80px] disabled:text-gray disabled:cursor-default disabled:no-underline my-first-step"
            disabled={!!eventInfo.date && !!eventInfo.time && isTimePassed}
            onClick={() => navigate(`/event/${circleCode}/edit`)}
          >
            Edit event
          </button>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <span className="mr-[35px] font-medium">
            Hosted by{' '}
            {eventInfo.hostName ? (
              eventInfo.hostName
            ) : (
              <span
                className=" text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => setHostNameModal(true)}
              >
                Add host name
              </span>
            )}
          </span>

          {/* <span className="text-primary cursor-pointer font-bold hover:underline min-w-[80px] disabled:text-gray disabled:cursor-default disabled:no-underline my-first-step">preview event</span> */}
        </div>

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md shadow-purple">
          <div className="flex flex-row items-center justify-start">
            <CalendarIcon />
            {eventInfo.date ? (
              <span className="ml-[8px]">
                {moment(eventInfo.date).format('MMMM DD, YYYY')}
              </span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/edit`)}
              >
                Add date
              </span>
            )}
          </div>
          <div className="flex flex-row items-center justify-start">
            <ClockIcon />
            {eventInfo.time ? (
              <span className="ml-[8px]">{timeOfEvent(eventInfo)}</span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/edit`)}
              >
                Add time
              </span>
            )}
          </div>
        </div>

        {isTimePassed &&
        !eventInfo?.address?.label ? null : hideUndefineFields &&
          !eventInfo?.address?.label ? (
          <></>
        ) : (
          <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md shadow-purple">
            <div className="flex flex-row items-center justify-start">
              <LocationIcon fill="#5b4abc" pinColor="#422d8f" />
              {eventInfo.address?.label ? (
                <div className="flex flex-col">
                  <button
                    className="ml-[8px] text-start"
                    disabled={isTimePassed}
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    {eventInfo.address?.label}
                    {eventInfo.address?.complementaryAddress
                      ? `, ${eventInfo.address?.complementaryAddress}`
                      : ''}
                  </button>
                  <button
                    className="ml-[8px] text-start"
                    disabled={isTimePassed}
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    {eventInfo.address?.label2}
                  </button>
                </div>
              ) : (
                <button
                  className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                  onClick={() =>
                    navigate(`/event/${circleCode}/edit?eventLocation=true`)
                  }
                >
                  Add event location
                </button>
              )}
            </div>
          </div>
        )}

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md shadow-purple">
          <div className="flex flex-row items-center justify-start">
            <PeopleOutlineIcon fill="#5b4abc" />
            <div>
              <span className="ml-[8px]">
                {!!guestsInfo ? Object.values(guestsInfo).length : 0} RSVPs
                •{' '}
              </span>
              <span
                className="text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => {
                  amplitude.track('Guest List Viewed', {
                    event_id: circleCode,
                    event_type: eventInfo?.ocassion ?? ''
                  })
                  navigate(`/event/${circleCode}/rsvps`)
                }}
              >
                View guests
              </span>
            </div>
          </div>
        </div>

        {isTimePassed && !eventInfo.costPerSquare ? null : hideUndefineFields &&
          !eventInfo.costPerSquare &&
          !hasPaymentOption ? (
          <></>
        ) : (
          <div className="mt-[12px] w-full shadow-purple rounded-md">
            <button
              className="bg-off-white p-3 w-full flex flex-col justify-center rounded-md cursor-pointer"
              onClick={() =>
                navigate(`/event/${circleCode}/edit?costPerGuest=true`)
              }
            >
              <div className="flex items-center">
                <CostIcon className="w-[24px] h-[24px]" fill={'#5B4ABC'} />

                <span
                  className={`ml-[8px] text-primary flex flex-1 font-semibold ${!eventInfo.costPerSquare && 'hover:underline cursor-pointer'}`}
                >
                  {hasPaymentOption
                    ? noFixedPrice
                      ? `View or edit payment options`
                      : `$${eventInfo.costPerSquare} per guest`
                    : '+ Cost per guest'}
                </span>

                {!!eventInfo.costPerSquare && !noFixedPrice ? (
                  <span className="text-primary underline cursor-pointer">
                    Edit payment options
                  </span>
                ) : null}
              </div>
            </button>
          </div>
        )}
      </div>

      <HostNameModal
        isOpen={hostNameModal}
        handleOnClose={() => setHostNameModal(false)}
        eventInfo={eventInfo}
      />

      <EventDetailsViewInviteDrawer
        isOpen={inviteModal}
        onClose={() => setInviteModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <EditEvent
        isOpen={editEventModal}
        handleOnClose={() => setEditEventModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <DisableInviteLinkModal
        onClose={handleDisableInviteLinkModalClose}
        isOpen={disableInviteLinkModal}
        onConfirm={handleDisableInviteLinkModalConfirm}
      />

      <EnableInviteLinkModal
        onClose={handleEnableInviteLinkModalClose}
        isOpen={enableInviteLinkModal}
        onConfirm={handleEnableInviteLinkModalConfirm}
      />
    </div>
  )
}

export default memo(EventDetailsView)

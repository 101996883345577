import React, { memo } from 'react'
import PopularCard from '../PopularCard/PopularCard'
import { useMediaQuery } from '../../../hook/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'
import SectionHeader from '../SectionHeader/SectionHeader'

const PopularEvents = ({ handleCreateEvent }) => {
  const navigate = useNavigate()

  const breakpoints = {
    sm: 600
  }

  const { sm } = useMediaQuery(breakpoints)

  const handleCardClick = (selection) => {
    amplitude.track('Create Event CTA Clicked', {
      event_type: selection,
      cta_label: 'popular events'
    })

    handleCreateEvent(`/event/new/${selection}`)
  }

  return (
    <SectionHeader
      title={'Popular events'}
      subtitle={'Get started on an invite with these templates'}
    >
      <div className="overflow-hidden mt-5 w-full p-2">
        <div className="flex flex-col sm:flex-row sm:gap-5 w-full">
          <PopularCard
            text={'Cocktail Parties'}
            image={require('../../../assets/images/home/drinks.jpg')}
            onClick={() => handleCardClick('cocktail-parties')}
          />
          <PopularCard
            text={'Birthday Parties'}
            image={require('../../../assets/images/home/ballons.jpg')}
            right={sm ? true : false}
            onClick={() => handleCardClick('birthday-parties')}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:gap-5 w-full">
          <PopularCard
            text={'Summer BBQ'}
            image={require('../../../assets/images/home/grill.jpg')}
            onClick={() => handleCardClick('summer-bbq')}
          />
          <PopularCard
            text={'Potlucks'}
            image={require('../../../assets/images/home/food.jpg')}
            right={sm ? true : false}
            onClick={() => handleCardClick('potlucks')}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:gap-5 w-full">
          <PopularCard
            text={'Baby Showers'}
            image={require('../../../assets/images/home/baby.jpg')}
            onClick={() => handleCardClick('baby-showers')}
          />
          <PopularCard
            text={'Bridal Showers'}
            image={require('../../../assets/images/home/ring.jpg')}
            right={sm ? true : false}
            onClick={() => handleCardClick('bridal-showers')}
          />
        </div>
      </div>

      <button
        className="px-5 py-2 rounded-full text-white font-semibold border-1 border-light-purple-3 shadow-purple hover:opacity-80 transition-all duration-200 max-w-[327px] w-full self-center mt-10 active:opacity-50"
        onClick={() => handleCreateEvent('/event/new')}
      >
        Build your own invite
      </button>
    </SectionHeader>
  )
}

export default memo(PopularEvents)

import React, { memo, useEffect, useState } from 'react'
import Base from '../../base'
import { useNavigate, useParams } from 'react-router-dom'
import RSVPEventDetailsView from '../../../components/RSVPEventDetailsView'
import EventCarouselView from '../../../components/EventCarouselView'
import { Helmet } from 'react-helmet'
import RequestTab from '../../EventDetails/Tab/RequestTab/RequestTab'
import MusicTab from '../../EventDetails/Tab/MusicTab/MusicTab'
import RSVPEventDetailsCostModal from '../../../components/RSVPEventDetails/RSVPEventDetailsCostModal/RSVPEventDetailsCostModal'
import { itemsInfo, previewEventInfo, previewGuests, previewSelectedSong } from './eventInfo'
import PreviewHeader from './PreviewHeader'

const PreviewDetails = () => {
  const { circleCode } = useParams()
  const navigate = useNavigate()
  const [toggleTab, setToggleTab] = useState(0)

  const [costModal, setCostModal] = useState(false)

  const eventInfo = previewEventInfo

  // FEATURE: Super Bowl
  const hasGame = false // !!eventInfo?.squaresGameId
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  useEffect(() => {
    if (!hasRequestList && hasMusic) {
      setToggleTab(1)
    }
  }, [hasMusic, hasRequestList])

  return (
    <React.Fragment>
      <RSVPEventDetailsCostModal
        isOpen={costModal}
        onClose={() => setCostModal(false)}
        eventInfo={eventInfo}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Coteri${!!eventInfo?.name ? ` - ${eventInfo.name}` : ''}`}</title>
          <meta
            name="description"
            content="See the details for your invitation. Track responses. See the music playlist."
          />
        </Helmet>

        <PreviewHeader />

        <div className="flex flex-1 items-center h-full flex-col relative">
          <div className="w-full flex gap-5 stripe-column:flex-row flex-col sm:mb-10 mb-0">
            <div className="w-full flex gap-5 flex-col sm:flex-row">
              <div className="order-1 sm:w-[42%] w-[100%] relative">
                <EventCarouselView
                  isGuest
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  oldAnswer={'yes'}
                  preview
                />
              </div>
              <div className="order-2 flex-1">
                <RSVPEventDetailsView
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  guestsInfo={previewGuests}
                  preview={true}
                />
              </div>
            </div>
          </div>

          {eventInfo.noteForGuests && (
            <div className="flex flex-col justify-center mt-[12px] w-full">
              <span>Additional event information</span>
              <div className="w-full h-[2px] bg-secondary" />

              <div className="bg-off-white p-3 flex flex-col justify-center rounded-md mt-2">
                <span>{eventInfo.noteForGuests}</span>
              </div>
            </div>
          )}

          <div className="w-full mt-[10px]">
            {hasGame && (
              <div
                className={`cursor-pointer relative flex flex-1 py-[10px] items-center justify-center rounded-md bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]`}
                onClick={() => {
                }}
              >
                <span>Super Bowl Squares</span>
              </div>
            )}

            <div>
              <ul
                className={`flex w-full items-center sm:gap-2 gap-2 mt-3 mb-4 sm:flex-row flex-col`}
              >
                {hasRequestList && (
                  <li
                    onClick={() => setToggleTab(0)}
                    className={`cursor-pointer flex relative w-full py-[10px] items-center justify-center rounded-md ${toggleTab === 0 ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                  >
                    <span>What to bring list</span>
                  </li>
                )}

                {hasMusic && (
                  <li
                    onClick={() => setToggleTab(1)}
                    className={`cursor-pointer flex relative w-full py-[10px] items-center justify-center rounded-md ${toggleTab === 1 ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                  >
                    <span>Music playlist</span>
                  </li>
                )}
              </ul>

              {hasRequestList ? (
                <RequestTab
                  circleInfo={eventInfo}
                  isToggled={toggleTab === 0}
                  itemsInfo={itemsInfo}
                  eventInfo={eventInfo}
                  guestsInfo={previewGuests}
                  rsvpResponse={'yes'}
                  title={
                    'Help out your host. Bring something from the list below to the event. You may also add your own item by clicking the "Add" button above.'
                  }
                  preview
                />
              ) : null}
              {hasMusic ? (
                <MusicTab
                  isToggled={toggleTab === 1}
                  circleInfo={eventInfo}
                  playlist={[previewSelectedSong]}
                  alreadyHasResponse={true}
                  rsvpResponse={'yes'}
                  preview
                />
              ) : null}
            </div>
          </div>
        </div>
      </Base>
    </React.Fragment>
  )
}

export default memo(PreviewDetails)

import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Checkbox from '../../../components/Checkbox/Checkbox'
import Button from '../../../components/Button/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Base from './../../base'
import { Controller, useForm } from 'react-hook-form'
import { ReactComponent as MailSVG } from '../../../assets/icons/mail.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { mappedCountryCodes } from '../../../utils/countryCodes'
import { phoneMasks } from '../../../utils/phoneMasks'
import RSVPEventDetailsView from '../../../components/RSVPEventDetailsView'
import EventCarouselView from '../../../components/EventCarouselView'
import ReactInputMask from 'react-input-mask'
import RSVPDecisionButton from '../../../components/RSVP/RSVPDecisionButton/RSVPDecisionButton'
import MuiThemeProvider from '../../../theme/mui'
import { previewEventInfo, previewGuests } from './eventInfo'
import PreviewHeader from './PreviewHeader'

export const schema = yup
  .object({
    name: yup.string().required(),
    phoneNumber: yup.string(),
    email: yup.string().email(),
    countryCode: yup.object().shape({
      value: yup.string().required()
    }),
    method: yup.string().default('phone')
  })
  .required()

const NewGuestPreview = () => {
  const { circleCode } = useParams()
  const navigate = useNavigate()

  const eventInfo = previewEventInfo

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    defaultValues: {
      method: 'phone',
      countryCode: mappedCountryCodes[0],
      phoneNumber: '877-527-7454',
      name: 'Dominic B',
      email: 'dominicb@email.com'
    }
  })

  const [countryCode, method] = form.watch([
    'countryCode',
    'method'
  ])

  const handleSubmit = () => {
    navigate(`/event/${circleCode}/preview/help?view=list`)
  }

  useEffect(() => {
    if (!countryCode) {
      form.setValue('countryCode', mappedCountryCodes[0])
    }
  }, [form, countryCode])

  const handlePhoneNumberMask = useMemo(() => {
    if (!countryCode) return
    const foundMask = phoneMasks.find((m) => m.value === countryCode.value)
    return foundMask ? foundMask.mask : '999999999999'
  }, [countryCode])

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      headerBackgroundColor={'transparent'}
      whiteLogo
    >
      <div className="flex flex-1 flex-col md:items-stretch">
        <PreviewHeader />

        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-2 flex-1">
            <RSVPEventDetailsView
              circleCode={circleCode}
              eventInfo={eventInfo}
              guestsInfo={previewGuests}
              preview={true}
            />
          </div>
          <div className="order-1 sm:w-[44%] w-[100%]">
            <EventCarouselView
              isGuest
              circleCode={circleCode}
              eventInfo={eventInfo}
              removeAttendingCard
            />
          </div>
        </div>

        {eventInfo.noteForGuests && (
          <div className="flex flex-col justify-center mt-[12px]">
            <span>Additional event information</span>
            <div className="w-full h-[2px] bg-secondary" />

            <div className="bg-off-white p-3 flex flex-col justify-center rounded-md mt-2">
              <span>{eventInfo.noteForGuests}</span>
            </div>
          </div>
        )}

        <div className="w-full mt-10 mb-10">
          <div className="text-[16px] text-primary font-semibold mb-6 sm:self-start self-center">
            Step 1: Select your response
          </div>
          <div className="w-full flex flex-col justify-between sm:flex-row gap-3">
            <RSVPDecisionButton
              going
              selected={true}
            />
            <RSVPDecisionButton
              onClick={() => { }}
              selected={false}
            />
          </div>
        </div>

        <div className="my-4">
          <div className="text-[16px] text-primary font-semibold mb-1 sm:self-start self-center">
            Step 2: Fill in your contact information
          </div>
          <p className="text-[14px] mb-6">
            You’ll receive a verification code to continue.
          </p>

          <div className="p-3 bg-off-white rounded-md">
            <div className="mb-6">
              <div className="font-semibold text-primary mb-2">
                Enter your name
              </div>
              <input
                {...form.register('name')}
                className={`mt-1 w-full rounded-md py-3 px-[14px] placeholder:text-primary cursor-default placeholder:opacity-70 outline-none ${form.formState.errors.name?.message ? 'border-1 border-error' : ''}`}
                placeholder="Enter name"
                type="text"
                readOnly
              />
            </div>

            <div className="font-semibold text-primary mb-3">
              Choose a verification method
            </div>

            <div className="px-[9px] bg-white rounded-md mb-2">
              <select
                {...form.register('method')}
                className="w-full text-start py-4 outline-none bg-white px-[1px]"
                disabled
              >
                <option value="email">Email</option>
                <option value="phone">Phone number</option>
              </select>
            </div>

            <MuiThemeProvider>
              {method === 'phone' ? (
                <div className="flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-[250px] relative">
                    <Controller
                      name="countryCode"
                      control={form.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            id="countryCode"
                            sx={{ width: '100%' }}
                            options={mappedCountryCodes}
                            value={value}
                            placeholder="Select country"
                            disableClearable
                            onChange={(_, v) => onChange(v)}
                            getOptionLabel={(option) => option?.label ?? ''}
                            readOnly
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props
                              return (
                                <Box
                                  key={key}
                                  component="li"
                                  autoFocus={false}
                                  sx={{
                                    '& > img': { mr: 2, flexShrink: 0 }
                                  }}
                                  {...optionProps}
                                >
                                  {option.label}
                                </Box>
                              )
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  slotProps={{
                                    htmlInput: {
                                      ...params.inputProps,
                                      autoComplete: 'new-password'
                                    }
                                  }}
                                  sx={{
                                    backgroundColor: '#FFFFFF !important',
                                    borderRadius: '8px !important'
                                  }}
                                />
                              )
                            }}
                          />
                        )
                      }}
                    />
                  </div>
                  <Controller
                    name={'phoneNumber'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactInputMask
                          mask={handlePhoneNumberMask}
                          className={`flex flex-1 rounded-md py-3 px-[14px] min-w-[40px] cursor-default placeholder:text-primary placeholder:opacity-70 outline-none ${form.formState.errors.phoneNumber?.message ? 'border-1 border-error' : ''}`}
                          type="tel"
                          placeholder="Enter phone number"
                          onChange={onChange}
                          value={value}
                          autoComplete="off"
                          maskChar={''}
                          readOnly
                        />
                      )
                    }}
                  />
                </div>
              ) : (
                <div className="flex gap-2">
                  <div className="flex items-center justify-center w-[55px] shrink-0 rounded-md bg-white">
                    <MailSVG style={{ width: 20, height: 20 }} />
                  </div>
                  <input
                    {...form.register('email')}
                    className={`w-full rounded-md py-3 px-[14px] placeholder:text-primary placeholder:opacity-70 cursor-default outline-none ${form.formState.errors.email?.message ? 'border-1 border-error' : ''}`}
                    placeholder="Enter email"
                    type="email"
                    readOnly
                  />
                </div>
              )}
            </MuiThemeProvider>

            <Checkbox
              checked={true}
              // onClick={() => setChecked(!checked)}
              text={`I consent to receive ${method === 'phone' ? 'texts' : 'emails'} from Coteri. This is for the purpose of sending verification codes, and will not be used to send marketing content or shared with other businesses or entities.`}
              className={'mt-4 items-start'}
              textClassName={'text-[14px]'}
              width={30}
            />
          </div>
        </div>
      </div>

      {!!form.formState.errors
        ? Object.entries(form.formState.errors).map(([errKey, errMessage]) => (
          <div key={errKey} className="text-red-400">
            {errMessage.message ?? ''}
          </div>
        ))
        : null}


      <div className="flex items-center mt-4 justify-end w-full mb-6">
        <Button
          text={'Continue'}
          size="medium"
          onClick={form.handleSubmit(handleSubmit)}
          disabled={form.formState.isSubmitting}
          loading={form.formState.isSubmitting}
        />
      </div>

    </Base>
  )
}

export default NewGuestPreview

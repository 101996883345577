export const previewEventInfo = {
  "address": {
    "city": "ILLinois",
    "complementaryAddress": "",
    "country": "United States",
    "label": "302 North Green Street",
    "label2": "Chicago, IllInois, Brazil",
    "state": "ILLinois",
    "value": "Rua Luís Góis",
    "zip": "8129312"
  },
  "allowGuests": true,  
  "completed": true,  
  "createdAt": "2025-02-19T23:28:33.101Z",
  "extraCollab": true,
  "hostEmail": "nilton.schumacher.filho@gmail.com",
  "hostID": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
  "hostName": "Cooper",
  "musicCollab": true,
  "name": "Chloe's Birthday",
  "noteForGuests": "Join us in celebrating Chloe's birthday! No presents please.",
  "ocassion": {
    "label": "Bridal Showers",
    "value": "bridal-showers"
  },
  "ocassionOther": "",
  "option": "guest",
  "playlistID": "5yxgxvvviySw0Y0OLkiNYZ",
  "provider": "spotify",
  "size": "10",
  "spotifyID": "dsadas",
  "date": "2025-02-19",    
  "time": "18:00",
  "timezone": "America/Chicago",
  "type": "event",
  "url": "https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral6.png?alt=media&token=5b360f43-24eb-4047-9aee-12491c7dc7ce"
}

export const itemsInfo = [
  {    
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Soda (12 Pack)",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "claimed": false,
  },
  {        
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Sparkling Water (12 Pack)",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "claimedBy": "Dominic B",
    "claimed": true,
  },
  {    
    "claimedBy": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Napkins",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03"
  },
  {    
    "claimedBy": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Plates",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03"
  },
  {    
    "claimedBy": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Cheese Plate",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03"
  },
  {    
    "claimedBy": "GD6ZDMMwrOeS9fCP8q2LBadK6r03",
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Vegetable Platter",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03"
  },
  {   
    "createdAt": "2025-02-19T16:17:51.275Z",
    "id": "5a14c9784b6d4d36984be0fb74f6e77f",
    "name": "Chips & Salsa",
    "owner": "GD6ZDMMwrOeS9fCP8q2LBadK6r03"
  },
]

export const previewSelectedSong = {
  "albumName": "The Rise and Fall of a Midwest Princess",
  "artistName": "Chappell Roan",
  "artists": [
    "7GlBOeep6PqTfFi59PTUUN"
  ],
  "artworkURL": "https://i.scdn.co/image/ab67616d0000b273a21d22ecf823e1c90aa22d1f",
  "href": "https://api.spotify.com/v1/tracks/6393yGahSJ9slVdOwSxOVR",
  "id": "6393yGahSJ9slVdOwSxOVR",
  "isrc": "USAT22001300",
  "name": "Pink Pony Club",
  "popularity": 80,
  "provider": "spotify",
  "releaseDate": "2023-09-21",
  "spotifyID": "6393yGahSJ9slVdOwSxOVR",
  "store": "us",
  "type": "track"
}

export const previewSongs = [
  {
    "albumName": "UP!",
    "artistName": "Forrest Frank & Connor Price",
    "artists": [
      "1scVfBymTr3CeZ4imMj1QJ",
      "5zixe6AbgXPqt4c1uSl94L"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b27308514486451188b525106b88",
    "href": "https://api.spotify.com/v1/tracks/50utqknCBo0N3QAQbXIPKV",
    "id": "50utqknCBo0N3QAQbXIPKV",
    "isrc": "QZES72407803",
    "name": "UP!",
    "popularity": 65,
    "provider": "spotify",
    "releaseDate": "2024-03-01",
    "spotifyID": "50utqknCBo0N3QAQbXIPKV",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "Time for a Change",
    "artistName": "FKJ",
    "artists": [
      "2FwDTncULUnmANIh7qKa5z"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273adcfbca38160b8ca89f0ccee",
    "href": "https://api.spotify.com/v1/tracks/7dKdozRcmMd2Q0XC45jv0U",
    "id": "7dKdozRcmMd2Q0XC45jv0U",
    "isrc": "FR9W11306654",
    "name": "Between Moods",
    "popularity": 30,
    "provider": "spotify",
    "releaseDate": "2013-07-08",
    "spotifyID": "7dKdozRcmMd2Q0XC45jv0U",
    "store": "fr",
    "type": "track"
  },
  {
    "albumName": "HIT ME HARD AND SOFT",
    "artistName": "Billie Eilish",
    "artists": [
      "6qqNVTkY8uBg9cP3Jd7DAH"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b27371d62ea7ea8a5be92d3c1f62",
    "href": "https://api.spotify.com/v1/tracks/6dOtVTDdiauQNBQEDOtlAB",
    "id": "6dOtVTDdiauQNBQEDOtlAB",
    "isrc": "USUM72401994",
    "name": "BIRDS OF A FEATHER",
    "popularity": 95,
    "provider": "spotify",
    "releaseDate": "2024-05-17",
    "spotifyID": "6dOtVTDdiauQNBQEDOtlAB",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "Zanaka (Deluxe)",
    "artistName": "Jain",
    "artists": [
      "2HHmvvSQ44ePDH7IKVzgK0"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b27364ba66f8a81c52364e55db50",
    "href": "https://api.spotify.com/v1/tracks/4TNFLwe6DhtR3Wn1JKMqMJ",
    "id": "4TNFLwe6DhtR3Wn1JKMqMJ",
    "isrc": "FR9W11508712",
    "name": "Makeba",
    "popularity": 59,
    "provider": "spotify",
    "releaseDate": "2016-11-25",
    "spotifyID": "4TNFLwe6DhtR3Wn1JKMqMJ",
    "store": "fr",
    "type": "track"
  },
  {
    "albumName": "MONTERO",
    "artistName": "Lil Nas X & Jack Harlow",
    "artists": [
      "7jVv8c5Fj3E9VhNjxT4snq",
      "2LIk90788K0zvyj2JJVwkJ"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273be82673b5f79d9658ec0a9fd",
    "href": "https://api.spotify.com/v1/tracks/5Z9KJZvQzH6PFmb8SNkxuk",
    "id": "5Z9KJZvQzH6PFmb8SNkxuk",
    "isrc": "USSM12104539",
    "name": "INDUSTRY BABY (feat. Jack Harlow)",
    "popularity": 72,
    "provider": "spotify",
    "releaseDate": "2021-09-17",
    "spotifyID": "5Z9KJZvQzH6PFmb8SNkxuk",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "Harry's House",
    "artistName": "Harry Styles",
    "artists": [
      "6KImCVD70vtIoJWnq6nGn3"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b2732e8ed79e177ff6011076f5f0",
    "href": "https://api.spotify.com/v1/tracks/1qEmFfgcLObUfQm0j1W2CK",
    "id": "1qEmFfgcLObUfQm0j1W2CK",
    "isrc": "USSM12200610",
    "name": "Late Night Talking",
    "popularity": 76,
    "provider": "spotify",
    "releaseDate": "2022-05-20",
    "spotifyID": "1qEmFfgcLObUfQm0j1W2CK",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "First Class",
    "artistName": "Jack Harlow",
    "artists": [
      "2LIk90788K0zvyj2JJVwkJ"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b2730fbbde391655703a7c8cdc79",
    "href": "https://api.spotify.com/v1/tracks/1rDQ4oMwGJI7B4tovsBOxc",
    "id": "1rDQ4oMwGJI7B4tovsBOxc",
    "isrc": "USAT22203024",
    "name": "First Class",
    "popularity": 28,
    "provider": "spotify",
    "releaseDate": "2022-04-08",
    "spotifyID": "1rDQ4oMwGJI7B4tovsBOxc",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "The Rise and Fall of a Midwest Princess",
    "artistName": "Chappell Roan",
    "artists": [
      "7GlBOeep6PqTfFi59PTUUN"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273a21d22ecf823e1c90aa22d1f",
    "href": "https://api.spotify.com/v1/tracks/6393yGahSJ9slVdOwSxOVR",
    "id": "6393yGahSJ9slVdOwSxOVR",
    "isrc": "USAT22001300",
    "name": "Pink Pony Club",
    "popularity": 80,
    "provider": "spotify",
    "releaseDate": "2023-09-21",
    "spotifyID": "6393yGahSJ9slVdOwSxOVR",
    "store": "us",
    "type": "track"
  }
]

export const previewGuests = [
  {
    "id": "a6d700f19c2029a08f168016bd5899f7",
    "name": "Christopher M",
    "phone": "+558928736126",
    "response": "yes",
    "verified": true
  },
]

import React from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import * as amplitude from '@amplitude/analytics-browser'

const StartYourInvite = ({ handleCreateEvent }) => {
  const handleClick = () => {
    amplitude.track('Create Event CTA Clicked', {
      cta_label: 'digital event',
      cta_position: 'bottom',
      event_type: 'digital event'
    })

    handleCreateEvent('/event/new')
  }

  return (
    <SectionHeader
      title={'Start building your invite!'}
      subtitle={
        'Join thousands who have used Coteri to create the perfect event'
      }
    >
      <button
        className="px-5 py-2 rounded-full text-white font-semibold border-1 border-light-purple-3 shadow-purple hover:opacity-80 transition-all duration-200 max-w-[327px] w-full self-center active:opacity-50"
        onClick={() => handleClick()}
      >
        Create your digital event
      </button>
    </SectionHeader>
  )
}

export default StartYourInvite

import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import { removeEventData } from '../services/database'
import LoginBox from '../components/LoginBox'
import RegisterBox from '../components/RegisterBox'
import ResetBox from '../components/ResetBox'
import {
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Home/Header/Header'
import PopularEvents from '../components/Home/PopularEvents/PopularEvents'
import Footer from '../components/Footer/Footer'
import * as amplitude from '@amplitude/analytics-browser'
import CreateInvite from '../components/Home/CreateInvite/CreateInvite'
import PerfectInvite from '../components/Home/PerfectInvite/PerfectInvite'
import CraftUltimateMusic from '../components/Home/CraftUltimateMusic/CraftUltimateMusic'
import PlanSuperBowl from '../components/Home/PlanSuperBowl/PlanSuperBowl'
import Faq from '../components/Home/Faq/Faq'
import StartYourInvite from '../components/Home/StartYourInvite/StartYourInvite'

const Home = () => {
  const [showPopUp, setShowPopUp] = useState(null)
  const [canvaPopUp, setCanvaPopUp] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [scrollReached, setScrollReached] = useState(0)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const toast = useToast()

  const handleSignUp = () => {
    setShowPopUp('register')
  }

  const handleSignIn = () => {
    setShowPopUp('login')
  }

  const handleSuccess = () => {
    setShowPopUp(null)
    if (!!redirect) {
      if (redirect.startsWith('canva=')) {
        setCanvaPopUp(redirect.substring(6, redirect.length - 1))
        return
      }
      navigate(redirect)
    }
  }

  const handleCreateEvent = (url) => {
    removeEventData()
    if (!user || user.isAnonymous) {
      setRedirect(url)
      setShowPopUp('register')
      return
    }
    navigate(url)
  }

  const handleScroll = () => {
    const perc = (window.screenY / window.height) * 100
    if (perc > 40 && scrollReached < 40) {
      setScrollReached(40)
      // logEvent(analytics, 'scrolled', {
      //   source: 'SCROLL_40%'
      // })
    }
    if (perc > 75 && scrollReached < 75) {
      setScrollReached(75)
      // logEvent(analytics, 'scrolled', {
      //   source: 'SCROLL_75%'
      // })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!loading && !!user) {
      // setUserId(analytics, user.uid)
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('uid', user.uid)
      amplitude.identify(identifyEvent)
      amplitude.setUserId(user.uid)
    }
  }, [user, loading])

  if (loading) {
    return null
  }

  return (
    <div>
      <title>Coteri</title>

      <Header handleSignUp={handleSignUp} handleSignIn={handleSignIn} />

      <CreateInvite handleCreateEvent={handleCreateEvent} />

      <PerfectInvite />

      <CraftUltimateMusic />

      {/* FEATURE: Super Bowl */}
      {/* <PlanSuperBowl handleCreateEvent={handleCreateEvent}/>            */}

      <PopularEvents handleCreateEvent={handleCreateEvent} />

      <Faq />

      <StartYourInvite handleCreateEvent={handleCreateEvent} />

      <Footer />

      <Modal isCentered isOpen={!!showPopUp} onClose={() => setShowPopUp(null)}>
        <ModalOverlay />
        <ModalContent py="12px" mx="20px">
          <ModalCloseButton mt="4px" mr="0" />
          <ModalBody>
            {showPopUp === 'login' && (
              <LoginBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'register' && (
              <RegisterBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'reset' && (
              <ResetBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default React.memo(Home)
